export default {
  'onlineOrderingSettings.title': 'Online Ordering',
  'onlineOrderingSettings.tabs.general': 'General',
  'onlineOrderingSettings.tabs.display': 'Display',
  'onlineOrderingSettings.tabs.feeAndTips': 'Fee & Tips',

  'onlineOrderingSettings.section.banner': 'Banner',
  'onlineOrderingSettings.section.notice': 'Notice',
  'onlineOrderingSettings.section.layout': 'Layout',
  'onlineOrderingSettings.section.businessHour': 'Business hour',
  'onlineOrderingSettings.section.specialTime': 'Special Hours',
  'onlineOrderingSettings.section.specialTime.timeOverlapDetailed':
    'Time range {current} overlaps with existing time range {overlapping}',
  'onlineOrderingSettings.section.menuHour': 'Menu hour',
  'onlineOrderingSettings.section.preparation': 'Preparation',
  'onlineOrderingSettings.section.autoConfirmOrders': 'Order Auto-Confirmation & Send',
  'onlineOrderingSettings.section.fee': 'Fee',
  'onlineOrderingSettings.section.tips': 'Tips',
  'onlineOrderingSettings.fee.new_fee': 'New Fee',
  'onlineOrderingSettings.fee.self_delivery': 'Self Delivery Fee',
  'onlineOrderingSettings.fee.table.action': 'Action',
  'onlineOrderingSettings.fee.table.no': 'No',
  'onlineOrderingSettings.fee.table.yes': 'Yes',
  'onlineOrderingSettings.fee.table.delivery.tip': 'Quick access when creating Online In delivery orders',

  'onlineOrderingSettings.section.preparation.prepareTime': 'Kitchen Prep Time',
  'onlineOrderingSettings.section.preparation.schedulingSettings': 'Maximum Schedule Days',
  'onlineOrderingSettings.section.notice.titleEN': 'Title',
  'onlineOrderingSettings.section.notice.contentEN': 'Content',
  'onlineOrderingSettings.section.notice.titleZH': 'Foreign Title',
  'onlineOrderingSettings.section.notice.contentZH': 'Foregin Content',
  'onlineOrderingSettings.section.layout.pc': 'PC Layout',
  'onlineOrderingSettings.section.layout.mobile': 'Mobile Layout',
  'onlineOrderingSettings.section.layout.recommend': ' (recommend)',
  'onlineOrderingSettings.section.layout.image_left': 'image-left',
  'onlineOrderingSettings.section.layout.image_right': 'image-right',
  'onlineOrderingSettings.section.layout.image_top': 'image-top',
  'onlineOrderingSettings.section.specialTime.allDaysClose': 'All days close',
  'onlineOrderingSettings.section.menuHour.guide': 'For Online Ordering’s Menu hour setting, please go to',
  'onlineOrderingSettings.section.autoConfirmOrders.guide':
    'For configuring auto-confirmation of online orders, please go',
  'onlineOrderingSettings.section.fee.guide': "For Online's others fee setting, please go",
  'onlineOrderingSettings.section.tips.guide': "For Online Ordering's Tips setting, please go ",
  'onlineOrderingSettings.section.tips.guide.takeOut':
    "Apply to take out orders of POS, QR, Kiosk and Call-in, for online ordering's tips setting, please go ",
  'onlineOrderingSettings.section.tips.guide.delivery':
    "Apply to delivery of call-in orders, for online ordering's tips setting, please go ",
  'onlineOrderingSettings.inputLimit': ' (Up To {maxCount} characters)',
  'onlineOrderingSettings.customTipMaxAmount': 'Customize maximum tip amount',

  'onlineOrderingSettings.action.addTime': 'Add Time',
  'onlineOrderingSettings.action.uploadBanner': 'Upload Banner',

  'onlineOrderingSettings.bannerUploadGuide':
    'The recommended uploaded image size is 1312 x 196 px, and the uploaded image format is JPG, JPEG; the size should be within 5M.',
  'onlineOrderingSettings.cropBanner': 'Crop Banner',

  'onlineOrderingSettings.low': 'Low',
  'onlineOrderingSettings.medium': 'Mid',
  'onlineOrderingSettings.high': 'High',
  'onlineOrderingSettings.default': 'Default',
  'onlineOrderingSettings.enable_dish_level_notes': 'Enable Dish Level Notes',
  'onlineOrderingSettings.enable_zh_under_en': 'Display Dish Foreign Name',
  'onlineOrderingSettings.enable_zh_under_en.tips':
    'The online ordering menu will display both English and the foreign language when the language is set to English',
  'onlineOrderingSettings.need_utensil': 'Enable “Need Utensil” Option',
  'onlineOrderingSettings.display_soldout': 'Display sold-out dishes and customizations',
  'onlineOrderingSettings.options': 'Option',
  'onlineOrderingSettings.pause_order': 'Pause Online Order',
  'onlineOrderingSettings.pause_pickup_order': 'Pause pickup orders',
  'onlineOrderingSettings.pause_delivery_order': 'Pause delivery orders',
  'onlineOrderingSettings.pause_order.content':
    'Online Ordering is temporarily closed and customers will not be able to place orders.',
  'onlineOrderingSettings.sync_link_to_gfo': 'Synchronize Link To GFO',
  'onlineOrderingSettings.sync_link_to_gfo.content': 'The GFO status should be updated within 6 hours.',
  'onlineOrderingSettings.weekday.max.three': 'You can add up to 3 time periods per day.',

  'onlineOrderingSettings.self_delivery.enable_self_delivery':
    'Enable  Delivery for online ordering.  For Self Delivery and Assign Driver setting, please go to ',
  'onlineOrderingSettings.self_delivery.status': 'Status',
  'onlineOrderingSettings.self_delivery.fee': 'For Delivery Fee setting, please go to ',
  'onlineOrderingSettings.self_delivery.delivery_area': 'Delivery Area',
  'onlineOrderingSettings.self_delivery.radius': 'Radius',
  'onlineOrderingSettings.self_delivery.miles': 'miles',
  'onlineOrderingSettings.self_delivery.delivery_time': 'Delivery Time',
  'onlineOrderingSettings.self_delivery.delivery_time.mins': 'mins',
  'onlineOrderingSettings.self_delivery.delivery_instructions': 'Delivery Instructions',
  'onlineOrderingSettings.self_delivery.enable_instructions':
    'Enable instructions to your guest for delivery order . This message will be seen on the order confirmation page and in the digital receipt, not in kitchen ticket.',
  'onlineOrderingSettings.self_delivery.assign_driver': 'Assign Driver',
  'onlineOrderingSettings.self_delivery.assign_driver_content':
    'When manually finishing an order, trigger a pop-up to assign a driver upon clicking "Finish".',

  'onlineOrderingSettings.section.fee.policy': 'Delivery Fee Policy',
  'onlineOrderingSettings.section.fee.fixed': 'Fixed Delivery Fee',
  'onlineOrderingSettings.section.fee.tiered.tip': '(Set the maximum delivery range first)',
  'onlineOrderingSettings.section.fee.tiered': 'Tiered Delivery Fee',
  'onlineOrderingSettings.section.fee.tiered.table.starting_mile': 'Starting Mile',
  'onlineOrderingSettings.section.fee.tiered.table.end_mile': 'End Mile',
  'onlineOrderingSettings.section.fee.amount': 'Amount',
  'onlineOrderingSettings.section.fee.mile_gt.required': 'Starting Mile is required',
  'onlineOrderingSettings.section.fee.mile_le.required': 'End Mile is required',
  'onlineOrderingSettings.section.fee.amount.required': 'Amount required',
  'onlineOrderingSettings.section.fee.exclusive': 'exclusive',
  'onlineOrderingSettings.section.fee.inclusive': 'inclusive',
  'onlineOrderingSettings.section.fee.add.policy': 'ADD',

  'onlineOrderingSettings.section.endmile.valid': 'The end distance must be greater than the start distance',
  'onlineOrderingSettings.section.fee.max_mile_le': 'The maximum end distance must be greater than or equal to',
  'onlineOrderingSettings.section.tired.remove': 'Remove',
};
