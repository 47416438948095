export default {
  'customerPickupScreenSettings.title': 'Customer Pickup Screen',
  'customerPickupScreenSettings.language': 'Language',
  'customerPickupScreenSettings.language.english': 'English',
  'customerPickupScreenSettings.language.englishAndChinese': 'English+Chinese(Simplified)',
  'customerPickupScreenSettings.language.chinese': 'Chinese(Simplified)',
  'customerPickupScreenSettings.screenTemplates': 'Screen Templates',
  'customerPickupScreenSettings.screenTemplates.upload': 'Upload',
  'customerPickupScreenSettings.screenTemplates.ready': 'Ready',
  'customerPickupScreenSettings.screenTemplates.inProgress': 'In Progress',
  'customerPickupScreenSettings.screenTemplates.preview': 'Preview',
  'customerPickupScreenSettings.screenTemplates.apply.yes': 'Applied',
  'customerPickupScreenSettings.screenTemplates.apply.no': 'Apply',
  'customerPickupScreenSettings.screenTemplates.upload.ads': 'Ads image',
  'customerPickupScreenSettings.screenTemplates.upload.bg': 'Bg image',
  'customerPickupScreenSettings.screenTemplates.tooltip.template1':
    'Support JPG, JPEG, GIF, PNG, limited to 3MB in size, ad image ratio 3:4, bg image ratio 1:1.',
  'customerPickupScreenSettings.screenTemplates.tooltip.template2':
    'Support JPG, JPEG, GIF, PNG, limited to 3MB in size, bg image ratio 16:9.',
  'customerPickupScreenSettings.screenTemplates.tooLargeSize': 'File size needs to be 3M or smaller',
  'customerPickupScreenSettings.screenTemplates.wrongFileType': 'File needs to be .jpeg, .gif, .png',
  'customerPickupScreenSettings.screenTemplates.uploadImageFailed': 'Handle image failed, please re-operate',
  'customerPickupScreenSettings.screenTemplates.templatePreview': 'Template Preview',
  'customerPickupScreenSettings.screenTemplates.require': 'Please select a screen template',
  'customerPickupScreenSettings.voiceCall': 'Voice Call',
  'customerPickupScreenSettings.voiceCall.checkbox':
    'Calling voice will be played when no. is displayed on customer pickup screen',
  'customerPickupScreenSettings.voiceCall.listen': 'Listen',
  'customerPickupScreenSettings.voiceCall.tooltip.title': 'Calling content: ',
  'customerPickupScreenSettings.voiceCall.tooltip.1':
    'Select "English" or "English+Chinese(Simplified)" as the language, and the calling voice is: "#Ticket no.# Please pick up your drinks".',
  'customerPickupScreenSettings.voiceCall.tooltip.2':
    'Select "Chinese(Simplified)" as the language, and the calling voice will play Mandarin.',
  'customerPickupScreenSettings.url': 'URL',
  'customerPickupScreenSettings.url.tips':
    'Please visit this URL on the customer pickup screen to display the ticket number.',
  'customerPickupScreenSettings.callingTimes': 'Calling Times',
  'customerPickupScreenSettings.callingTimes.1': 'Call 1 time',
  'customerPickupScreenSettings.callingTimes.n': 'Call {callingTime} times',
};
